<template>
    <main class="relative h-screen">
        <div class="max-w-8xl mx-auto h-full grid grid-row-auto gap-0 grid-cols-1 lg:grid-cols-12 lg:justify-between">
            <section class="bg-white px-4 pt-4 pb-8 lg:h-full lg:col-span-7 lg:justify-between">
                <nav class="mb-4 flex justify-center lg:justify-start">
                    <KeapLogo />
                </nav>
                <div class="max-w-lg mx-auto lg:mt-10">
                    <h1 class="text-black-default font-walsheim font-bold mb-2 text-center md:text-left leading-none">Start your free trial today</h1>
                    <p class="text-black-default mb-8 text-center lg:text-left">No credit card required</p>
                    <p class="text-gray-80 max-w-md text-base mb-8 text-center lg:text-left">
                        Unlock your Keap free trial and put the power of sales and marketing automation to work for you over the next 14 days.
                    </p>
                </div>
                <form action="" ref="mainForm" @submit.prevent="submit" class="max-w-lg mx-auto">
                    <div v-if="currentStep == 0" key="step-0">
                        <InputField
                            key="email-input"
                            v-model="email"
                            :value="email"
                            data-qa="email-input"
                            :valid="emailValid"
                            field-label="Business email"
                            field-type="email"
                            required
                            class="mb-4"
                            :loading="$store.getters.isLoading"
                        >
                            <template v-slot:error>
                                <p v-text="emailError"></p>
                            </template>
                        </InputField>
                        <p class="text-xs text-gray-2 mb-4">Select email features are limited to users with a non-business email address. Enter yours now for full access.</p>
                        <KeapButton
                            :disabled="!emailValid || (nextSubmitting && $store.getters.isTestBox)"
                            small-expanded
                            class="flex gap-2 flex-row items-center justify-center mb-4"
                            @click.prevent="nextStep()"
                            data-qa="next-button"
                        >
                            <Spinner size="w-5 h-5" inner-color="text-transparent" outer-color="fill-white" v-if="nextSubmitting && $store.getters.isTestBox" />Next
                        </KeapButton>

                        <p class="text-xs text-gray-2 mb-4">By clicking "Next", I agree to receive content and marketing emails from Keap.</p>
                        <img src="//assets.keap.com/image/upload/c_scale,w_0.33/v1658444506/keap/keap-pages/free-trial/stars.png" class="max-w-full mb-8" alt="G2 and Google star rating">
                        <p>Already signed up for a free trial? <Link href="/return-user" @click="onLinkClick('Access now')" data-qa="login-link">Access now</Link></p>
                        <p>Already a Keap subscriber? <Link href="https://signin.infusionsoft.com/login" @click="onLinkClick('Login')" data-qa="login-link">Login</Link></p>
                    </div>
                    <div v-else-if="currentStep == 1" key="step-1">
                        <InputField
                            key="firstName-input"
                            v-model="firstName"
                            :value="firstName"
                            data-qa="firstName-input"
                            :valid="firstNameValid"
                            field-label="First name"
                            field-type="text"
                            class="mb-8"
                            required
                        >
                            <template v-slot:error>
                                <p v-text="firstNameError"></p>
                            </template>
                        </InputField>
                        <InputField
                            key="lastName-input"
                            v-model="lastName"
                            :value="lastName"
                            data-qa="lastName-input"
                            :valid="lastNameValid"
                            field-label="Last name"
                            field-type="text"
                            class="mb-8"
                            required
                        >
                            <template v-slot:error>
                                <p v-text="lastNameError"></p>
                            </template>
                        </InputField>
                        <PhoneField
                            key="phone-input"
                            v-model="phone"
                            :value="phone"
                            data-qa="phone-input"
                            :valid="phoneValid"
                            field-label="Phone"
                            field-type="tel"
                            class="mb-8"
                            required
                        >
                            <template v-slot:error>
                                <p v-text="phoneError"></p>
                            </template>
                        </PhoneField>
                        <PasswordField
                            key="password-input"
                            ref="passwordInput"
                            v-model="password"
                            :value="password"
                            data-qa="password-input"
                            :valid="passwordValid"
                            field-label="Password"
                            field-type="password"
                            class="mb-8"
                            required
                            v-if="!$store.getters.isTestBox"
                        >
                            <template v-slot:error>
                                <p v-text="passwordError"></p>
                            </template>
                        </PasswordField>
                        <KeapCheckbox
                            name="privacy_policy"
                            v-model="privacyAgree"
                            :valid="privacyAgree"
                            :value="privacyAgree"
                            data-qa="privacy-policy"
                            required
                        >
                            <template v-slot:label>
                                I accept the Keap <a href="https://keap.com/legal/terms-of-use" rel="noopener" @click="onLinkClick('Terms of Use')" target="_blank" class="font-bold underline">Terms of Use</a> and have read the Keap <a href="https://keap.com/legal/privacy-policy/" rel="noopener" @click="onLinkClick('Privacy Policy')" target="_blank" class="font-bold underline">Privacy Policy</a>.
                            </template>
                            <template v-slot:error>
                                <p>To begin your free trial, you must agree to Keap’s privacy policy.</p>
                            </template>
                        </KeapCheckbox>
                        <p class="text-xs text-gray-2 mb-4">By clicking "Start my free trial" below, I agree to receive Keap’s marketing text messages via automated technology. Consent is not required to purchase services. I understand that I can revoke my consent at any time.</p>
                        <KeapButton
                            :disabled="!canSubmit()"
                            small-expanded
                            class="block mb-4"
                            type="submit"
                            data-qa="submit-button"
                        >
                            Start my free trial
                        </KeapButton>
                        <Link
                            class="block"
                            @click.prevent="prevStep()"
                            arrow
                            data-qa="previous-button"
                        >
                            Previous
                        </Link>
                    </div>
                    <EhawkTalon ref="ehawkTalon"/>
                </form>
            </section>
            <section class="bg-gray-6 lg:h-full lg:col-span-5 flex flex-col justify-start py-8 px-4 lg:pt-24">
                <img src="https://assets.keap.com/image/upload/q_100/v1677007375/keap/keap-pages/free-trial/free_trial_568.webp" onerror="this.onerror=null; this.src='https://assets.keap.com/image/upload/q_100/v1677007375/keap/keap-pages/free-trial/free_trial_568.png'" alt="Man with arms crossed surrounded by floating graphics">
            </section>
        </div>
        <div class="fixed w-screen h-full bg-gradient-to-r from-white to-gray-6 from-50% to-50% top-0 left-0 z-[-1] hidden lg:block"></div>
        <BuildingStatus v-if="$store.getters.submitting"/>
        <Errors />
        <Maintenance v-if="$store.getters.isMaintenance"/>
    </main>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import KeapLogo from '@/../svg/logo.svg?component';

import PhoneField from '@/FreeTrial/Components/PhoneField.vue';
import PasswordField from '@/FreeTrial/Components/PasswordField.vue';
import InputField from '@/FreeTrial/Components/InputField.vue';
import KeapCheckbox from '@/FreeTrial/Components/KeapCheckbox.vue';
import KeapButton from '@/Components/KeapButton.vue';
import Link from '@/Components/Link.vue';
import Spinner from '@/Components/Spinner.vue';
import Errors from '@/FreeTrial/Components/Modals/Errors.vue';
import Maintenance from '@/FreeTrial/Components/Modals/Maintenance.vue';
import EhawkTalon from '@/BuyNow/Components/inputs/EhawkTalon.vue';

import Echo from 'laravel-echo';

import { wait } from '@/FreeTrial/helpers.js';
// import * as Sentry from '@/Util/window.sentry.js';
import { LanguageCurrencyMap, EMAIL_REGEX, PHONE_REGEX } from '@/FreeTrial/constants.js';

import * as Analytics from '@/Util/reporting.js';

const constants = window.constants;

export default {
    components: {
        InputField,
        PasswordField,
        PhoneField,
        KeapCheckbox,
        KeapButton,
        Link,
        Spinner,
        KeapLogo,
        EhawkTalon,
        Maintenance,
        BuildingStatus: defineAsyncComponent(() => {
            return import('@/FreeTrial/Components/BuildStatus/BuildingStatus.vue');
        }),
        Errors,
    },
    data() {
        return {
            Echo: {},
            buildTimeOut: {},
            transitionName: 'slide-left',
            currentStep: 0,
            privacyAgree: false,
            emailCaptured: false,
            emailValid: false,
            firstNameValid: false,
            lastNameValid: false,
            phoneValid: false,
            passwordValid: false,
            nonTimeoutError: false,
            appSuccessful: false,
            wsOpen: false,
            nextSubmitting: false,
            featurePathMap: new Map([
                ['keap-business-line', '/communication'],
                ['automation', '/automations/easy'],
                ['email-marketing', '/broadcasts/email'],
                ['appointments', '/appointments'],
                ['quotes', '/sales/invoices'],
                ['invoices-payments', '/sales/invoices'],
                ['sales-pipeline', '/pipeline'],
                ['email-marketing', '/broadcasts'],
                ['crm', '/contacts'],
                ['integrations', '/settings/integrations'],
            ])
        };
    },
    watch: {
        email(newVal, oldVal) {
            if (newVal == '') {
                this.emailValid = false;
                this.emailError = 'Business email is required';
            } else if (!RegExp(EMAIL_REGEX).test(newVal)) {
                this.emailValid = false;
                this.emailError = 'Business email is not valid';
            } else {
                this.emailValid = true;
            }
        },
        firstName(newVal, oldVal) {
            if (newVal == '') {
                this.firstNameValid = false;
                this.firstNameError = 'First name is required';
            } else {
                this.firstNameValid = true;
            }
        },
        lastName(newVal, oldVal) {
            if (newVal == '') {
                this.lastNameValid = false;
                this.lastNameError = 'Last name is required';
            } else {
                this.lastNameValid = true;
            }
        },
        phone(newVal, oldVal) {
            if (newVal == '') {
                this.phoneValid = false;
                this.phoneError = 'Phone is required';
            } else if (!RegExp(PHONE_REGEX).test(newVal)) {
                this.phoneValid = false;
                this.phoneError = 'Phone is not valid';
            } else {
                this.phoneValid = true;
            }
        },
        async password(newVal, oldVal) {
            await this.$nextTick;
            if (newVal == '') {
                this.passwordValid = false;
                this.passwordError = 'Password is required';
            } else if (!this.$store.getters.passwordValid) {
                this.passwordValid = false;
                this.passwordError = 'Password must meet requirements';
            } else {
                this.passwordValid = true;
            }
        },
    },
    methods: {
        async focusFirstField() {
            await this.$nextTick();
            (this.$refs.mainForm).querySelector('input').focus();
        },
        async submit() {
            const _this = this;
            this.$refs.ehawkTalon.updateTalon();
            await _this.$store.commit('setSubmitting', true);

            Analytics.pushToDataLayer({
                event: 'click',
                attributes: {
                    section: 'Signup Form',
                    action: 'Clicked submit form button',
                    event_label: 'All fields valid',
                },
            });

            grecaptcha.enterprise.ready(async () => {
                const token = await grecaptcha.enterprise.execute(Keap.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY, { action: 'FREE_TRIAL_SIGNUP' });
                await _this.$store.commit('setRecaptchaToken', token);

                await _this.$store.commit('setTransactionId', new String(this.randomNumber(1000000000000000, 9007199254740991)));

                try {
                    if (!this.$store.getters.isTestBox) {
                        _this.buildTimeOut = setTimeout(_this.handleTimeout, 90000, _this.$store);
                    }

                    await _this.$store.dispatch('submitTrial');

                    if (!this.$store.getters.isTestBox) {
                        // Free Trial Conversion event - CrazyEgg
                        (window.CE_API || (window.CE_API=[])).push(function(){
                            // Demo/FT Conversion goal
                            CE2.converted("4cb8d9c8-2f45-4a30-b077-2d4faaef9cc4");

                            // Free Trial Conversion goal
                            CE2.converted("95167471-a2a8-41c2-ac12-d87c6950d8b0");
                        });
                        // End Free Trial Conversion event - CrazyEgg
                        const wsResult = await _this.openWebsocket();

                        Analytics.pushToDataLayer({
                            event: 'free_trial_signup',
                            attributes: {
                                section: 'Free Trial',
                                action: 'App Created',
                                customer_email: _this.email,
                                customer_firstname: _this.firstName,
                                customer_lastname: _this.lastName,
                                transaction_id: _this.$store.getters.transactionId,
                            },
                        });

                        await wait(1000);

                        if (typeof wsResult.tenantId !== 'undefined') {
                            _this.redirectToApp(wsResult.tenantId, _this.$store.getters.userInfo.jwt);
                        }
                    } else {
                        window.location.replace(this.$store.getters.redirectLocation);
                    }
                } catch (error) {
                    console.error(error);
                    clearTimeout(_this.buildTimeOut);
                    _this.nonTimeoutError = true;
                    if (window.axios.isAxiosError(error)) {
                        if (!!error.response) {
                            const errorObj = error.response.data;

                            if (errorObj.error === window.constants.errorCodes.ehawkBlocked) {
                                Analytics.pushToDataLayer({
                                    event: 'error',
                                    attributes: {
                                        section: 'Free Trial Signup',
                                        action: 'Signup Blocked',
                                        event_label: 'E-hawk',
                                    },
                                });
                            } else if (errorObj.error === window.constants.errorCodes.recaptchaBlocked) {
                                Analytics.pushToDataLayer({
                                    event: 'error',
                                    attributes: {
                                        section: 'Free Trial Signup',
                                        action: 'Signup Blocked',
                                        event_label: 'Recaptcha',
                                    },
                                });
                            } else if (errorObj.error === window.constants.errorCodes.trialExists) {
                                Analytics.pushToDataLayer({
                                    event: 'error',
                                    attributes: {
                                        section: 'Free Trial Signup',
                                        action: 'Signup Stopped',
                                        event_label: 'Account already exists',
                                    },
                                });
                            } else {
                                window.Sentry.logError(error, errorObj);
                            }

                            _this.$store.commit('setError', {
                                active: true,
                                code: errorObj.error
                            });
                        } else {
                            window.Sentry.logError(error);
                            _this.$store.commit('setError', {
                                active: true,
                                code: window.constants.errorCodes.generalError
                            });
                        }
                        _this.$store.commit('setSubmitting', false);
                    } else if (!_this.$store.getters.hasActiveError) {
                        window.Sentry.logError(error);
                        _this.$store.commit('setError', {
                            active: true,
                            code: window.constants.errorCodes.generalError
                        });
                        _this.$store.commit('setSubmitting', false);
                    }
                }

            });
        },
        closeWebsocket() {
            const _this = this;
            clearTimeout(_this.buildTimeOut);
            _this.Echo.leave(`instant-access.${_this.$store.getters.subscriptionId}`);
        },
        openWebsocket() {
            const _this = this;
            const amplitudeEventProps = {
                [constants.amplitudeEventProperties.source]: constants.amplitudeConstants.keapCartWeb,
                [constants.amplitudeEventProperties.eventType]: constants.amplitudeConstants.freeEditionSignupPage,
                [constants.amplitudeEventProperties.signupType]: constants.amplitudeConstants.email,
            };

            return new Promise((resolve, reject) => {
                _this.Echo = new Echo({
                    broadcaster: 'pusher',
                    key: Keap.env.VITE_PUSHER_APP_KEY,
                    cluster: Keap.env.VITE_PUSHER_APP_CLUSTER,
                    forceTLS: true
                });
                _this.wsOpen = true;

                _this.Echo
                    .channel(`instant-access.${_this.$store.getters.subscriptionId}`)
                    .listen('.app-created', async (e) => {
                        _this.appSuccessful = true;
                        _this.closeWebsocket();

                        Analytics.amplitude.event(
                            constants.amplitudeEvents.appCreationSuccess,
                            amplitudeEventProps
                        )

                        await wait(3000);
                        resolve(e.eventData);
                    })
                    .listen('.app-failed', (e) => {
                        _this.nonTimeoutError = true;
                        window.Sentry.logInfo(new Error('app-failed'), e);
                        _this.closeWebsocket();
                        this.$store.commit('setError', {
                            active: true,
                            code: window.constants.errorCodes.generalError
                        });
                        this.$store.commit('setSubmitting', false);

                        Analytics.amplitude.event(
                            constants.amplitudeEvents.appCreationFailed,
                            amplitudeEventProps
                        );

                        Analytics.pushToDataLayer({
                            event: 'error',
                            attributes: {
                                section: 'Signup Form',
                                action: 'Instant Access Error Triggered',
                                event_label: 'app-failed',
                            },
                        });

                        resolve(e.eventData);
                    })
                    .listen('.app-failed-account-link', (e) => {
                        _this.nonTimeoutError = true;
                        window.Sentry.logInfo(new Error('app-failed-account-link'), e);
                        _this.closeWebsocket();
                        this.$store.commit('setError', {
                            active: true,
                            code: window.constants.errorCodes.checkEmail
                        });
                        this.$store.commit('setSubmitting', false);

                        Analytics.amplitude.event(
                            constants.amplitudeEvents.appCreationFailed,
                            amplitudeEventProps
                        );

                        Analytics.pushToDataLayer({
                            event: 'error',
                            attributes: {
                                section: 'Signup Form',
                                action: 'Instant Access Error Triggered',
                                event_label: 'app-failed-account-link',
                            },
                        });

                        _this.$store.dispatch('sendNewFTNotification');

                        Analytics.pushToDataLayer({
                            event: 'free_trial_signup',
                            attributes: {
                                section: 'Free Trial',
                                action: 'App Created',
                                customer_email: _this.email,
                                customer_firstname: _this.firstName,
                                customer_lastname: _this.lastName,
                                transaction_id: _this.$store.getters.transactionId,
                            },
                        });

                        resolve(e.eventData);
                    })
                    .listen('.app-failed-update-user', (e) => {
                        _this.nonTimeoutError = true;
                        window.Sentry.logInfo(new Error('app-failed-update-user'), e);
                        _this.closeWebsocket();
                        this.$store.commit('setError', {
                            active: true,
                            code: window.constants.errorCodes.generalError
                        });
                        this.$store.commit('setSubmitting', false);

                        Analytics.amplitude.event(
                            constants.amplitudeEvents.appCreationFailed,
                            amplitudeEventProps
                        );

                        Analytics.pushToDataLayer({
                            event: 'error',
                            attributes: {
                                section: 'Signup Form',
                                action: 'Instant Access Error Triggered',
                                event_label: 'app-failed-update-user',
                            },
                        });

                        resolve(e.eventData);
                    })
                    .listen('.app-failed-missing-cas-id', (e) => {
                        _this.nonTimeoutError = true;
                        window.Sentry.logInfo(new Error('app-failed-missing-cas-id'), e);
                        _this.closeWebsocket();
                        this.$store.commit('setError', {
                            active: true,
                            code: window.constants.errorCodes.generalError
                        });
                        this.$store.commit('setSubmitting', false);

                        Analytics.amplitude.event(
                            constants.amplitudeEvents.appCreationFailed,
                            amplitudeEventProps
                        );

                        Analytics.pushToDataLayer({
                            event: 'error',
                            attributes: {
                                section: 'Signup Form',
                                action: 'Instant Access Error Triggered',
                                event_label: 'app-failed-missing-cas-id',
                            },
                        });

                        resolve(e.eventData);
                    })
                    .listen('.app-failed-unknown-enum', (e) => {
                        _this.nonTimeoutError = true;
                        window.Sentry.logInfo(new Error('app-failed-unknown-enum'), e);
                        _this.closeWebsocket();
                        this.$store.commit('setError', {
                            active: true,
                            code: window.constants.errorCodes.generalError
                        });
                        this.$store.commit('setSubmitting', false);

                        Analytics.amplitude.event(
                            constants.amplitudeEvents.appCreationFailed,
                            amplitudeEventProps
                        );

                        Analytics.pushToDataLayer({
                            event: 'error',
                            attributes: {
                                section: 'Signup Form',
                                action: 'Instant Access Error Triggered',
                                event_label: 'app-failed-unknown-enum',
                            },
                        });

                        resolve(e.eventData);
                    })
                    .listen('.app-failed-bypass', (e) => {
                        _this.nonTimeoutError = true;
                        window.Sentry.logInfo(new Error('app-failed-bypass'), e);
                        _this.closeWebsocket();
                        this.$store.commit('setError', {
                            active: true,
                            code: window.constants.errorCodes.bypassFailed
                        });
                        this.$store.commit('setSubmitting', false);

                        Analytics.amplitude.event(
                            constants.amplitudeEvents.appCreationFailed,
                            amplitudeEventProps
                        );

                        Analytics.pushToDataLayer({
                            event: 'error',
                            attributes: {
                                section: 'Signup Form',
                                action: 'Instant Access Error Triggered',
                                event_label: 'app-failed-bypass',
                            },
                        });

                        resolve(e.eventData);
                    })
                    .listen('.app-failed-provisioning', (e) => {
                        _this.nonTimeoutError = true;
                        window.Sentry.logInfo(new Error('app-failed-provisioning'), e);
                        _this.closeWebsocket();
                        this.$store.commit('setError', {
                            active: true,
                            code: window.constants.errorCodes.bypassFailed
                        });
                        this.$store.commit('setSubmitting', false);

                        Analytics.amplitude.event(
                            constants.amplitudeEvents.appCreationFailed,
                            amplitudeEventProps
                        );

                        Analytics.pushToDataLayer({
                            event: 'error',
                            attributes: {
                                section: 'Signup Form',
                                action: 'Instant Access Error Triggered',
                                event_label: 'app-failed-provisioning',
                            },
                        });

                        resolve(e.eventData);
                    })
                    .listen('.unknown-error', (e) => {
                        _this.nonTimeoutError = true;
                        window.Sentry.logInfo(new Error('unknown-error'), e);
                        _this.closeWebsocket();
                        this.$store.commit('setError', {
                            active: true,
                            code: window.constants.errorCodes.generalError
                        });
                        this.$store.commit('setSubmitting', false);

                        Analytics.amplitude.event(
                            constants.amplitudeEvents.appCreationFailed,
                            amplitudeEventProps
                        );

                        Analytics.pushToDataLayer({
                            event: 'error',
                            attributes: {
                                section: 'Signup Form',
                                action: 'Instant Access Error Triggered',
                                event_label: 'unknown-error',
                            },
                        });

                        resolve(e.eventData);
                    });
            });
        },
        onLinkClick(label) {
            Analytics.pushToDataLayer({
                event: 'click',
                attributes: {
                    section: 'Signup Form',
                    action: 'Clicked link',
                    event_label: label,
                },
            });
        },
        async nextStep() {
            switch(this.currentStep) {
                case 0:
                    if (this.$store.getters.isTestBox) {
                        this.nextSubmitting = true;
                        let hasTestBox = await this.$store.dispatch('checkTestBox');
                        this.nextSubmitting = false;

                        if (hasTestBox) {
                            // TODO: If user clicks "signin", I need to hit a new 'signin' endpoint that will fetch the user from TB and redirect to the provided location.

                            this.$store.commit('setError', {
                                active: true,
                                code: window.constants.errorCodes.testBoxExists
                            });

                            Analytics.pushToDataLayer({
                                event: 'click',
                                attributes: {
                                    section: 'Signup Form',
                                    action: 'Clicked next step button',
                                    event_label: 'TestBox account exists',
                                },
                            });

                            return;
                        }
                    }

                    if (!this.emailCaptured) {
                        this.captureEmail();
                    }

                    Analytics.pushToDataLayer({
                        event: 'click',
                        attributes: {
                            section: 'Signup Form',
                            action: 'Clicked next step button',
                            event_label: 'Valid email',
                        },
                    });

                    break;
            }
            this.currentStep += 1;
            await this.$nextTick();
            (this.$refs.mainForm).querySelector('input').focus();
        },
        async prevStep() {
            Analytics.pushToDataLayer({
                event: 'click',
                attributes: {
                    section: 'Signup Form',
                    action: 'Clicked previous step button',
                },
            });

            this.currentStep -= 1;
            await this.$nextTick();
            (this.$refs.mainForm).querySelector('input').focus();
        },
        redirectToApp(tenantId, jwt) {
            const urlSearchParams = new URLSearchParams(window.location.search);

            const {
                feature, utm_campaign, utm_content, utm_medium, utm_source, utm_term,
            } = Object.fromEntries(urlSearchParams.entries());
            const path = this.featurePathMap.get(feature);

            const outputParams = new URLSearchParams({
                app_id: tenantId, ticket: jwt || '', utm_campaign, utm_content, utm_medium, utm_source, utm_term,
            });

            if (path) {
                outputParams.append('isFromOnboarding', 'true');
                outputParams.append('initOnboardingWithoutPage', 'true');

                window.location.assign(`${Keap.env.VITE_KEAP_APP_URL}${path}?${outputParams.toString()}`);
            } else {
                window.location.assign(`${Keap.env.VITE_KEAP_APP_URL}/dashboard?${outputParams.toString()}`);
            }
        },
        async captureEmail() {
            const _this = this;
            try {
                await _this.$store.dispatch('captureLead', true);
                _this.emailCaptured = true;
            } catch (error) {
                // Log error and move on
                window.Sentry.logError(error);
            }
        },
        canSubmit() {
            if (this.emailValid && this.firstNameValid && this.lastNameValid && this.phoneValid && (this.$store.getters.isTestBox || this.passwordValid) && this.privacyAgree) {
                return true;
            }

            return false;
        },
        handleTimeout(store) {
            if (this.wsOpen && !!store.getters.subscriptionId) {
                this.closeWebsocket(store.getters.subscriptionId);
            }

            if (!this.appSuccessful && !this.nonTimeoutError) {
                Analytics.amplitude.event(
                    constants.amplitudeEvents.appCreationFailed,
                    {
                        [constants.amplitudeEventProperties.source]: constants.amplitudeConstants.keapCartWeb,
                        [constants.amplitudeEventProperties.eventType]: constants.amplitudeConstants.freeEditionSignupPage,
                        [constants.amplitudeEventProperties.signupType]: constants.amplitudeConstants.email,
                    }
                );

                Analytics.pushToDataLayer({
                    event: 'error',
                    attributes: {
                        section: 'Signup Form',
                        action: 'Error Triggered',
                        event_label: constants.amplitudeEvents.appCreationFailed + ' - converted',
                    },
                });

                //* If we have a subscription ID that means Chargebee got the users data so it is a successful conversion
                if (!!store.getters.subscriptionId) {
                    Analytics.pushToDataLayer({
                        event: 'free_trial_signup',
                        attributes: {
                            section: 'Free Trial',
                            action: 'App Created',
                            customer_email: this.email,
                            customer_firstname: this.firstName,
                            customer_lastname: this.lastName,
                            transaction_id: this.$store.getters.transactionId,
                        },
                    });

                    this.$store.dispatch('sendNewFTNotification', {
                        cbId: store.getters.subscriptionId,
                    }).catch(error => {
                        window.Sentry.logError(error)
                    });
                }

                store.commit('setError', {
                    active: true,
                    code: window.constants.errorCodes.checkEmail
                });
                store.commit('setSubmitting', false);
            }
        },
        randomNumber(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
    },
    computed: {
        firstName: {
            get() {
                return this.$store.state.userInfo.firstName;
            },
            set(value) {
                this.$store.commit('updateUserInfo', { firstName: value });
            }
        },
        lastName: {
            get() {
                return this.$store.state.userInfo.lastName;
            },
            set(value) {
                this.$store.commit('updateUserInfo', { lastName: value });
            }
        },
        email: {
            get() {
                return this.$store.state.userInfo.email;
            },
            set(value) {
                this.$store.commit('updateUserInfo', { email: value });
            }
        },
        password: {
            get() {
                return this.$store.state.userInfo.password;
            },
            set(value) {
                this.$store.commit('updateUserInfo', { password: value });
            }
        },
        phone: {
            get() {
                return this.$store.state.userInfo.phone;
            },
            set(value) {
                this.$store.commit('updateUserInfo', { phone: value });
            }
        },
    },
    async mounted() {
        let selectedCurrency = LanguageCurrencyMap.get(navigator.language);

        if (!selectedCurrency) {
            selectedCurrency = 'USD';
        }

        this.$store.commit('updateUserInfo', { currency: selectedCurrency });
        const query = new URLSearchParams(window.location.search);
        if (window.location.pathname === '/') {
            await this.$store.dispatch('loadStateFromQuery', query);
        } else {
            this.$store.commit('setLoading', true);
            await this.$store.dispatch('loadStateFromDB');
        }
        await this.$store.dispatch('loadStateFromCookie');
        // ? Do we need to validate the partners? We may need to do it if we're doing bundle ID stuff.
        // if (!!this.$store.getters.partnerAccountId) {
        //     this.$store.commit('setLoading', true);
        //     await this.$store.dispatch('checkPartner');
        // }
        this.$store.commit('setLoading', false);
        await this.$store.dispatch('setTracking', query);

        this.focusFirstField();
    }
};
</script>

<style lang="postcss" scoped>
</style>
